import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../css/pages/second-chance.scss"

const SecondChanceProgram = () => {
  return (
    <Layout>
      <SEO
        title="Second Chance Program"
        description="It is time for a brand new, healthy, beautiful smile at Oral Surgery Specialists of Oklahoma."
      />

      <div className="sas">
        <div className="sas__hero sas__section">
          <img
            className="sas__hero-img--desktop"
            src="/OKC/DEV/second-chance-desktop-hero-image.png"
            alt="second chance banner"
          />
          <img
            className="sas__hero-img--mobile"
            src="/OKC/DEV/second-chance-mobile-hero-image.png"
            alt="second chance banner"
          />
        </div>

        <div className="sas__body sas__section">
          <div className="sas__container small">
            <h1>A Second Chance for a Healthy Smile</h1>
            <p>
              Oral Surgery Specialists of Oklahoma is proud to announce its
              seventh Second Chance program. This life-changing program provides
              one deserving recipient from the Oklahoma City area with a
              complete smile makeover free of charge.
            </p>
            <p>
              The ideal candidate has multiple missing or failing teeth and
              cannot afford restorative treatment. After the submission period,
              we will select one recipient to receive a free{" "}
              <Link
                to="/full-arch-restoration/"
                title="Learn more about full-arch restoration">
                full-arch restoration
              </Link>{" "}
              procedure. We encourage anyone in our community who is dreaming of
              a healthy, beautiful new smile to apply!
            </p>
            <h3>Full-Arch Restoration</h3>
            <p>
              Full-arch restoration is a healthy and effective way to replace
              many missing teeth. The procedure works by securing a full
              prosthesis in the mouth using as few as four dental implants. The
              dental implant posts will permanently hold your new teeth in place
              while also preventing bone loss.{" "}
            </p>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container">
            <div className="sas__brackets">
              <div className="sas__about-grid">
                <div>
                  <p className="sas__subheading">How To Apply</p>
                  <ul>
                    <li>Complete the short application.</li>
                    <li>Upload photos of your smile and teeth.</li>
                    <li>Submit your application by October 6, 2023.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="sas__section">
          <div className="sas__container small">
            <p>
              <b>
                Thank you for your interest in the Second Chance program. The
                application period is now closed. Applicants will be notified
                once the selection process has been completed.
              </b>
            </p>
            <p>
              Stay tuned to this page and our{" "}
              <a
                href="https://www.facebook.com/OralSurgerySpecialistsofOklahoma/"
                title="Follow us on Facebook"
                target="_blank">
                Facebook
              </a>{" "}
              page for program updates!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <Link
                  to="/dental-implants-oklahoma-city-ok/"
                  title="Learn more about dental implants">
                  dental implants
                </Link>{" "}
                and{" "}
                <Link
                  to="/full-arch-restoration/"
                  title="Learn more about full-arch-restoration">
                  full-arch restoration
                </Link>{" "}
                to see how they can benefit your smile. Whether you need to
                replace one tooth or an entire arch of teeth, our team has a
                solution for you.
              </i>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SecondChanceProgram
